<template>
  <v-main>
    <v-container fluid>
      <router-view />
    </v-container>
    <dashboard-core-footer />
  </v-main>
</template>

<script>
export default {
  name: "DashboardCoreView",

  components: {
    DashboardCoreFooter: () => import("./Footer"),
  },
};
</script>
